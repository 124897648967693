/** @jsx jsx */
import React from 'react'
import { graphql } from 'gatsby'
import { jsx } from 'theme-ui'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../layout/layout'

import { Hero, Question, Section } from '../components'

class FaqIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const question = get(this.props, 'data.allContentfulQuestion.edges')

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={`Frequently Asked Questions | ${siteTitle}`} />
          <Hero
            title='Frequently Asked Questions'
            subtitle='These are some questions we are commonly asked, if you require some further imformation or
would like to book an appointment please contacts us using one of the methods below and one
of our experts will get back to you;'
            size={[4, 6]}
            minHeight={[0, '40vh']}
            button='Contact us'
            buttonUrl='/contact'
          />

          <Section variant='transparent'>
            <div
              sx={{
                display: 'grid',
                gridTemplateColumns: [
                  'repeat(auto-fill, minmax(250px, 1fr))',
                  'repeat(auto-fill, minmax(400px, 1fr))',
                ],
                gridAutoRows: '1fr',
                gridGap: 4,
                alignItems: 'stretch',
              }}
            >
              {question.map(({ node }) => {
                return <Question data={node} key={node.slug} />
              })}
            </div>
          </Section>
        </div>
      </Layout>
    )
  }
}

export default FaqIndex

export const pageQuery = graphql`
  query FaqIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulQuestion {
      edges {
        node {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
